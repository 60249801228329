import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Footer from '../components/footer';
import Header from '../components/header';
import Content from '../components/content';
import SEO from '../components/seo';
import Analytics from '../components/analytics';

import serviceStyle from '../styles/service.module.less';

export default () => {
    const imageData = useStaticQuery(graphql`
        query {
            safeImage: file(relativePath: { eq: "safe.png" }) {
                childImageSharp {
                    fixed(height: 151, width: 151) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            freeImage: file(relativePath: { eq: "free.png" }) {
                childImageSharp {
                    fixed(height: 151, width: 151) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            updateImage: file(relativePath: { eq: "update.png" }) {
                childImageSharp {
                    fixed(height: 151, width: 151) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    const serviceContentList = [
        {
            title: '一对一服务',
            content1: '专属客服顾问，',
            content2: '提供一站式服务',
            content3: '',
            colorClass: ''
        },
        {
            title: '用户备份',
            content1: '除系统自动备份数据外',
            content2: '用户也可自行定期备份，',
            content3: '一键导出，方便快捷。',
            colorClass: serviceStyle.warpColorGray
        },
        {
            title: '远程协助',
            content1: '全年无限次远程协助故障处理，',
            content2: '高效、直接的解决您的问题',
            content3: '',
            colorClass: serviceStyle.warpColorYellow
        },
        {
            title: '7x12小时在线客服',
            content1: '7×12小时电话/在线服务，',
            content2: '迅速、便捷的响应您的要求',
            content3: '',
            colorClass: serviceStyle.warpColorPurple
        },
        {
            title: '在线培训',
            content1: '新开客户产品使用在线培训',
            content2: '帮助您快速上手',
            content3: '',
            colorClass: serviceStyle.warpColorGreen
        },
        {
            title: '培训班',
            content1: '产品操作培训班的参加名额',
            content2: '(6人次/年)',
            content3: '随时掌握产品更新进度和使用方法',
            colorClass: serviceStyle.warpColorBlue
        }
    ];
    const servicePromiseList = [
        {
            title: '数据安全',
            logo: '',
            img: imageData.safeImage.childImageSharp.fixed
        },
        {
            title: '立即体验',
            logo: '',
            img: imageData.freeImage.childImageSharp.fixed
        },
        {
            title: '无限升级',
            logo: '',
            img: imageData.updateImage.childImageSharp.fixed
        }
    ];
    return (
        <section>
            <SEO title="服务承诺" />
            <Header />

            <Content className={serviceStyle.service}>
                <h2 className={serviceStyle.serviceHeader}>用户专属服务</h2>
                <ul className={serviceStyle.serviceContent}>
                    {serviceContentList.map((service, index) => (
                        <li
                            key={index}
                            className={`${
                                index % 2 === 0 ? serviceStyle.warpLeft : serviceStyle.warpRight
                            } ${service.colorClass}`}
                        >
                            <span className={serviceStyle.wordWhite}>VIP FOR YOU</span>
                            <div className={serviceStyle.serviceContentInside}>
                                <h2>{service.title}</h2>
                                <p></p>
                                <span>
                                    {service.content1}
                                    <br />
                                    {service.content2}
                                    <br />
                                    {service.content3}
                                </span>
                            </div>
                        </li>
                    ))}
                </ul>
            </Content>
            <div style={{ backgroundColor: '#f8f8fa' }}>
                <Content className={serviceStyle.servicePromise}>
                    <div>
                        <h3>我们的承诺</h3>
                        <h4>高标准服务铸就高水准企业</h4>
                        <ul className={serviceStyle.servicePromiseContent}>
                            {servicePromiseList.map((value, index) => (
                                <section key={index}>
                                    <li>
                                        <Img fixed={value.img} alt={value.title} />
                                        <p>{value.title}</p>
                                    </li>
                                    {index !== servicePromiseList.length - 1 && (
                                        <li>
                                            <span>+</span>
                                        </li>
                                    )}
                                </section>
                            ))}
                        </ul>
                    </div>
                </Content>
            </div>
            <Analytics />
            <Footer />
        </section>
    );
};
